import React, { useState } from "react";
import img1 from "images/content/2_3img01.jpg";
import img2 from "images/content/2_3title01.jpg";
import img3 from "images/content/2_3title02.jpg";
import img4 from "images/content/2_3title03.jpg";
import img5 from "images/content/2_3img02.jpg";
import img6 from "images/content/2_3img03.jpg";
import img7 from "images/content/2_3img04.jpg";
import img8 from "images/content/2_3img05.jpg";
import img9 from "images/content/2_3img06.jpg";

const Page03 = () => {
  const [activeTab, setActiveTab] = useState("tab01");
  const [activeSubTab, setActiveSubTab] = useState({
    subTab01: "subTab01_1",
    subTab02: "subTab02_1",
    subTab03: "subTab03_1",
  });

  const changeTab = (tabId) => {
    setActiveTab(tabId);
    if (tabId === "tab01") {
      setActiveSubTab({
        subTab01: "subTab01_1",
        subTab02: "subTab02_1",
        subTab03: "subTab03_1",
      });
    }
  };

  const changeSubTab = (e, tabTitle, tabId) => {
    e.preventDefault();
    setActiveSubTab((prevState) => ({
      ...prevState,
      [tabTitle]: tabId,
    }));
  };

  return (
    <div className="content_section FloatRight">
      <div className="content_top">
        <h2>
          솔루션<span>The Best Leader of Information Technology</span>
        </h2>
        <p className="content_navi">
          <span className="home">HOME</span>
          <span className="gt">&gt;</span>
          <span>사업소개</span>
          <span className="gt">&gt;</span>
          <span>솔루션</span>
        </p>
      </div>
      <div className="content_in">
        <div>
          <ul className="tabmenu02 tabsize_6">
            <li id="tab01" className={activeTab === "tab01" ? "active" : ""}>
              <a href="#" onClick={() => changeTab("tab01")}>
                그룹웨어
              </a>
            </li>
            <li id="tab02" className={activeTab === "tab02" ? "active" : ""}>
              <a href="#" onClick={() => changeTab("tab02")}>
                콘텐츠관리시스템
              </a>
            </li>
            <li id="tab03" className={activeTab === "tab03" ? "active" : ""}>
              <a href="#" onClick={() => changeTab("tab03")}>
                교육관리시스템
              </a>
            </li>
            <li id="tab04" className={activeTab === "tab04" ? "active" : ""}>
              <a href="#" onClick={() => changeTab("tab04")}>
                물류관리시스템
              </a>
            </li>
            <li id="tab05" className={activeTab === "tab05" ? "active" : ""}>
              <a href="#" onClick={() => changeTab("tab05")}>
                통합검색엔진
              </a>
            </li>
            <li id="tab06" className={activeTab === "tab06" ? "active" : ""}>
              <a href="#" onClick={() => changeTab("tab06")}>
                MSA
              </a>
            </li>
          </ul>

          {activeTab === "tab01" && (
            <span id="tab01_content">
              <img src={img1} alt="그룹웨어" />
              <img src={img2} className="MAT30" alt="그룹웨어 타이틀" />
              <ul id="subTab01" className="tabmenu02 tabsize_5 MAT10" style={{ height: "365px" }}>
                <li id="subTab01_1" className={activeSubTab.subTab01 === "subTab01_1" ? "active" : ""}>
                  <a href="#" onClick={(e) => changeSubTab(e, "subTab01", "subTab01_1")}>
                    전자결재(기업)
                  </a>
                  {activeSubTab.subTab01 === "subTab01_1" && (
                    <div className="page2_3box page2_3box01">
                      <p className="title">
                        기업의 업무환경에 최적화된 전자결재로
                        <br />
                        <span>결재시간 단축, 결재문서의 관리</span> 등 업무를 개선합니다.
                      </p>
                      <ul className="box_in_li">
                        <li>다양한 결재 프로세스 및 옵션 제공</li>
                        <li>보안결재, 부재설정, 대결지정, 걸재 기한 알림</li>
                        <li>문서 및 결재선 변경 및 변경 이력 확인</li>
                        <li>역할에 따른 위임 전결 관리기능</li>
                        <li>폼 빌더 방식을 통한 결재 양식의 WYSIWYG 실현</li>
                        <li>외부 시스템 연동을 위한 연동API 및 스크립트 편집기 제공</li>
                      </ul>
                    </div>
                  )}
                </li>
                <li id="subTab01_2" className={activeSubTab.subTab01 === "subTab01_2" ? "active" : ""}>
                  <a href="#" onClick={(e) => changeSubTab(e, "subTab01", "subTab01_2")}>
                    전자결재(공공)
                  </a>
                  {activeSubTab.subTab01 === "subTab01_2" && (
                    <div className="page2_3box page2_3box02">
                      <p className="title">
                        전자문서 <span>표준규격 및 신사무관리규정을 준수</span>하며
                        <br />
                        문서유통을 지원합니다.
                      </p>
                      <ul className="box_in_li">
                        <li>한글 기안기와 웹 기안기 제공 (정부 및 공공기관 전용)</li>
                        <li>내부결재/대내문서/대외문서(전자, 민원, 기타) 결재유형</li>
                        <li>일괄 기안 기능 제공</li>
                        <li>기록물관리를 통한 완결문서의 편철/인계/인수</li>
                        <li>문서 접수과/ 처리과 업무지원</li>
                      </ul>
                    </div>
                  )}
                </li>
                <li id="subTab01_3" className={activeSubTab.subTab01 === "subTab01_3" ? "active" : ""}>
                  <a href="#" onClick={(e) => changeSubTab(e, "subTab01", "subTab01_3")}>
                    문서함
                  </a>
                  {activeSubTab.subTab01 === "subTab01_3" && (
                    <div className="page2_3box page2_3box03">
                      <p className="title">
                        <span>다양한 문서와 자료를 체계적으로 관리</span>하여
                        <br />
                        신속하게 검색 및 활용 할 수 있습니다.
                      </p>
                      <ul className="box_in_li">
                        <li>다차원 문서 분류 기능</li>
                        <li>문서의 버전관리 및 반입/반출기능</li>
                        <li>문서별 세부권한 지정</li>
                        <li>문서 등록 승인 기능</li>
                        <li>종결된 결재문서 자동/수동 이관</li>
                      </ul>
                    </div>
                  )}
                </li>
                <li id="subTab01_4" className={activeSubTab.subTab01 === "subTab01_4" ? "active" : ""}>
                  <a href="#" onClick={(e) => changeSubTab(e, "subTab01", "subTab01_4")}>
                    근태관리
                  </a>
                  {activeSubTab.subTab01 === "subTab01_4" && (
                    <div className="page2_3box page2_3box04">
                      <p className="title">
                        출근, 퇴근, 휴가, 출장 등 <span>사내 인적자원 관리</span>를<br />
                        보다 편리하게 할 수 있습니다.
                      </p>
                      <ul className="box_in_li">
                        <li>지문인식기와 연동하여 출퇴근 기록</li>
                        <li>근태신청시 전자결재 연동 승인</li>
                        <li>구성원의 부재 현황 파악</li>
                        <li>사용자별 평균 출퇴근 시간 등 통계</li>
                        <li>근태보고서 조회, 출력, 엑셀 저장</li>
                      </ul>
                    </div>
                  )}
                </li>
                <li id="subTab01_5" className={activeSubTab.subTab01 === "subTab01_5" ? "active" : ""}>
                  <a href="#" onClick={(e) => changeSubTab(e, "subTab01", "subTab01_5")}>
                    주소록
                  </a>
                  {activeSubTab.subTab01 === "subTab01_5" && (
                    <div className="page2_3box page2_3box05">
                      <p className="title">
                        사용자 및 조직과 관련된{" "}
                        <span>
                          연락처를 관리하고
                          <br />
                          공유할 수 있습니다.
                        </span>
                      </p>
                      <ul className="box_in_li">
                        <li>부서 연락처 공유</li>
                        <li>연락처 상세등록, 빠른 등록지원</li>
                        <li>중복연락처 체크를 통한 주소록 정리기능</li>
                        <li>주소록 내보내기 / 아웃룩 주소록 가져오기</li>
                        <li>SMS 연동, 명함인식기 연동가능</li>
                      </ul>
                    </div>
                  )}
                </li>
              </ul>
              <img src={img3} className="MAT30" alt="협업관리 타이틀" />
              <ul id="subTab02" className="tabmenu02 tabsize_4 MAT10" style={{ height: "365px" }}>
                <li id="subTab02_1" className={activeSubTab.subTab02 === "subTab02_1" ? "active" : ""}>
                  <a href="#" onClick={(e) => changeSubTab(e, "subTab02", "subTab02_1")}>
                    협업관리
                  </a>
                  {activeSubTab.subTab02 === "subTab02_1" && (
                    <div className="page2_3box page2_3box06">
                      <p className="title">
                        협업관리는 그룹{" "}
                        <span>
                          업무에 필수적인 공유, 협업
                          <br />
                          소통을 위한 공간
                        </span>
                        입니다.
                      </p>
                      <ul className="box_in_li">
                        <li>프로젝트 생성 및 관리</li>
                        <li>팀원 업무의 진행 현황 및 수정이력 조회</li>
                        <li>스페이스 간 전부 또는 일부 공유 시스템 지원</li>
                        <li>프로젝트 관련 메일, 전자결재 연계</li>
                        <li>공유자료, 산출물 관리 위한 게시판/문서함 이관</li>
                      </ul>
                    </div>
                  )}
                </li>
                <li id="subTab02_2" className={activeSubTab.subTab02 === "subTab02_2" ? "active" : ""}>
                  <a href="#" onClick={(e) => changeSubTab(e, "subTab02", "subTab02_2")}>
                    자원예약
                  </a>
                  {activeSubTab.subTab02 === "subTab02_2" && (
                    <div className="page2_3box page2_3box07">
                      <p className="title">
                        회의실, 차량 등의 자원에 대해{" "}
                        <span>
                          예약을 하고
                          <br />
                          현황을 기간별로 조회
                        </span>
                        할 수 있습니다.
                      </p>
                      <ul className="box_in_li">
                        <li>예약제, 승인제 자원관리</li>
                        <li>자원별 사용 권한 등록</li>
                        <li>자원예약과 동시에 자동 일정등록</li>
                        <li>자원별 일일/주간/월간 예약현황 조회</li>
                        <li>예약, 승인대기, 반납대기/지연 현황 캘린더 및 카운터</li>
                      </ul>
                    </div>
                  )}
                </li>
                <li id="subTab02_3" className={activeSubTab.subTab02 === "subTab02_3" ? "active" : ""}>
                  <a href="#" onClick={(e) => changeSubTab(e, "subTab02", "subTab02_3")}>
                    일정관리
                  </a>
                  {activeSubTab.subTab02 === "subTab02_3" && (
                    <div className="page2_3box page2_3box08">
                      <p className="title">
                        개인 또는 조직별로{" "}
                        <span>
                          일정을 공유하여
                          <br />
                          효율적이고 계획적인 업무처리
                        </span>
                        가 가능합니다.
                      </p>
                      <ul className="box_in_li">
                        <li>Ajax 기반으로 동적 사용자 UI 제공</li>
                        <li>구글 및 아웃룩 일정관리와 동기화</li>
                        <li>일정 알림 기능</li>
                        <li>일정 등록 시 자원예약 연동</li>
                        <li>일일, 주간, 월간 일정 및 할일, 기념일 관리</li>
                      </ul>
                    </div>
                  )}
                </li>
                <li id="subTab02_4" className={activeSubTab.subTab02 === "subTab02_4" ? "active" : ""}>
                  <a href="#" onClick={(e) => changeSubTab(e, "subTab02", "subTab02_4")}>
                    파일함
                  </a>
                  {activeSubTab.subTab02 === "subTab02_4" && (
                    <div className="page2_3box page2_3box09">
                      <p className="title">
                        개인폴더, 공용폴더, 외부폴더를 통해
                        <br />
                        <span>파일을 공유하는 웹하드</span> 기능입니다.
                      </p>
                      <ul className="box_in_li">
                        <li>폴더 용량관리 기능</li>
                        <li>폴더 내 업/다운로드 등 활동 알림</li>
                        <li>모바일 스캐너와 연동된 스캔보관함</li>
                        <li>그룹웨어 내 첨부파일 등록시 파일함에서 첨부 지원</li>
                        <li>윈도우탐색기 인터페이스 적용한 파일함 탐색기 제공</li>
                      </ul>
                    </div>
                  )}
                </li>
              </ul>
              <img src={img4} className="MAT30" alt="전자우편 타이틀" />
              <ul id="subTab03" className="tabmenu02 tabsize_5 MAT10" style={{ height: "365px" }}>
                <li id="subTab03_1" className={activeSubTab.subTab03 === "subTab03_1" ? "active" : ""}>
                  <a href="#" onClick={(e) => changeSubTab(e, "subTab03", "subTab03_1")}>
                    전자우편
                  </a>
                  {activeSubTab.subTab03 === "subTab03_1" && (
                    <div className="page2_3box page2_3box10">
                      <p className="title">
                        한화금융그룹에 납품했던 경험으로
                        <br />
                        <span>안정성이 검증된 메일</span>을 개발하여 제공합니다.
                      </p>
                      <ul className="box_in_li">
                        <li>대용량 메일 발송 기능</li>
                        <li>메일 내 메모 작성기능</li>
                        <li>승인메일 및 보안메일로 사내 보안 실현</li>
                        <li>계층형 개인 편지함, 메일 자동분류 기능</li>
                        <li>메일 수발신 로그조회 및 백업관리기능</li>
                      </ul>
                    </div>
                  )}
                </li>
                <li id="subTab03_2" className={activeSubTab.subTab03 === "subTab03_2" ? "active" : ""}>
                  <a href="#" onClick={(e) => changeSubTab(e, "subTab03", "subTab03_2")}>
                    게시판
                  </a>
                  {activeSubTab.subTab03 === "subTab03_2" && (
                    <div className="page2_3box page2_3box11">
                      <p className="title">
                        <span>다양한 게시판 속성과 항목을 직접 디자인</span>할 수 있는
                        <br />
                        툴을 제공하여 업무 활용도를 높였습니다.
                      </p>
                      <ul className="box_in_li">
                        <li>업무에 적합한 목록 및 조회화면 디자인</li>
                        <li>중요공지, 게시기한, 게시 알림 기능 제공</li>
                        <li>리스트, 라디오버튼, 체크박스 등 다양한 컬럼 형식 지원</li>
                        <li>게시판형, 자료실형, 뉴스형, 앨범형의 View 화면</li>
                        <li>익명, 지식, 핫라인, 승인, 대여/신청 등의 20여가지 속성의 게시판</li>
                      </ul>
                    </div>
                  )}
                </li>
                <li id="subTab03_3" className={activeSubTab.subTab03 === "subTab03_3" ? "active" : ""}>
                  <a href="#" onClick={(e) => changeSubTab(e, "subTab03", "subTab03_3")}>
                    쪽지
                  </a>
                  {activeSubTab.subTab03 === "subTab03_3" && (
                    <div className="page2_3box page2_3box12">
                      <p className="title">
                        메일 전송을 대신하여 <span>쉽고 빠르게 메시지를 전송</span>하며
                        <br />
                        알림을 받을 수 있습니다.
                      </p>
                      <ul className="box_in_li">
                        <li>받는사람, 참조자, 내용, 첨부파일 전송 기능</li>
                        <li>메신저 쪽지 알림 및 모바일 Push 알림</li>
                        <li>개인쪽지함 추가 및 백업</li>
                        <li>조직도, 마이리스트(자주 연락하는 사용자)연동</li>
                        <li>모바일 연동으로 시간 장소 제약없는 메시지 전달</li>
                      </ul>
                    </div>
                  )}
                </li>
                <li id="subTab03_4" className={activeSubTab.subTab03 === "subTab03_4" ? "active" : ""}>
                  <a href="#" onClick={(e) => changeSubTab(e, "subTab03", "subTab03_4")}>
                    설문조사
                  </a>
                  {activeSubTab.subTab03 === "subTab03_4" && (
                    <div className="page2_3box page2_3box13">
                      <p className="title">
                        조직 내/외부 <span>사용자를 대상으로 의견을 수렴</span>하여
                        <br />
                        조직을 개선할 수 있습니다.
                      </p>
                      <ul className="box_in_li">
                        <li>메일을 통한 외부 설문조사</li>
                        <li>다양한 설문 문항 및 설문문항 분기 기능</li>
                        <li>익명 및 답변 공개여부 선택</li>
                        <li>설문결과 통계 제공, 엑셀 저장</li>
                        <li>기존설문 재사용 기능</li>
                      </ul>
                    </div>
                  )}
                </li>
                <li id="subTab03_5" className={activeSubTab.subTab03 === "subTab03_5" ? "active" : ""}>
                  <a href="#" onClick={(e) => changeSubTab(e, "subTab03", "subTab03_5")}>
                    동호회
                  </a>
                  {activeSubTab.subTab03 === "subTab03_5" && (
                    <div className="page2_3box page2_3box01">
                      <p className="title">
                        동호회 운영을 통해 <span>사내 문화를 개선</span>할 수 있습니다.
                        <br />
                        (포털 사이트에서 제공하는 카페 형식)
                      </p>
                      <ul className="box_in_li">
                        <li>멤버 등급, 스텝, 메뉴 관리</li>
                        <li>동호회 분류, 랭킹 제공</li>
                        <li>커버스토리, 메뉴, 스킨, 레이아웃 설정</li>
                        <li>등급별 동호회 할당용량 설정</li>
                        <li>방문현황, 멤버현황, 가입탈퇴현황 등 각종 통계</li>
                      </ul>
                    </div>
                  )}
                </li>
              </ul>
            </span>
          )}

          {activeTab === "tab02" && <img id="tab02_content" src={img5} alt="콘텐츠관리시스템" />}
          {activeTab === "tab03" && <img id="tab03_content" src={img6} alt="교육관리시스템" />}
          {activeTab === "tab04" && <img id="tab04_content" src={img7} alt="물류관리시스템" />}
          {activeTab === "tab05" && <img id="tab05_content" src={img8} alt="통합검색엔진" />}
          {activeTab === "tab06" && (
            <div id="tab06_content">
              <center>
                <img src={img9} style={{ width: "300px" }} alt="MSA" /> <br />
                <br />
                <span>
                  {" "}
                  <h2>페이지 준비중입니다 </h2>
                </span>
              </center>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Page03;
