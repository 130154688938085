import React from "react";
import "css/timeLine.css";

const TimelineItem = ({ year, comment }) => (
  <div className="timeline-item">
    <div className="timeline-year">History {year}</div>
    <div className="timeline-content">
      <ul>
        {comment.map((comment, index) => (
          <li key={index}>
            <span className="dot"></span>
            {comment}
          </li>
        ))}
      </ul>
    </div>
  </div>
);

const Timeline = ({ data }) => (
  <div className="timeline">
    {data.map((item, index) => (
      <TimelineItem key={index} year={item.year} comment={item.comment} />
    ))}
  </div>
);

export default Timeline;
