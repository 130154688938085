import React, { useEffect } from "react";
import img from "images/content/1_5img01.jpg";

const Page05 = () => {
  useEffect(() => {
    console.log("확인",process.env.REACT_APP_URL)
  }, []);
  return (
    <div className="content_section FloatRight">
      <div className="content_top">
        <h2>
          회사조직<span>The Best Leader of Information Technology</span>
        </h2>
        <p className="content_navi">
          <span className="home">HOME</span>
          <span className="gt">&gt;</span>
          <span>회사소개</span>
          <span className="gt">&gt;</span>
          <span>회사조직</span>
        </p>
      </div>
      <div className="content_in">
        <div>
          <img src={img} alt="회사조직 이미지" />
        </div>
      </div>
    </div>
  );
};

export default Page05;
