import React from "react";
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, } from "@react-google-maps/api";
import img from "images/content/1_6img01.jpg";

const containerStyle = {
  width: "600px",
  height: "500px",
};

const center = {
  lat: 37.536341,
  lng: 126.894896,
};

const markerTitle = "다이나시스템";
const contentString = `
  <div>
    <h2>다이나시스템</h2>
    <p>주소 :서울특별시 영등포구 선유로49길 23 선유도역 2차<br />
    아이에스비즈빌딩 7층 716호</p>
  </div>`;

const Page06 = () => {
  const [selected, setSelected] = React.useState(null);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyB-vWFyn29rgEt3Fbg0Exhg91d0ceeOzMc",
    language: "ko",
    region: "KR",
  });

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div className="content_section FloatRight">
      <div className="content_top">
        <h2>
          오시는 길<span>The Best Leader of Information Technology</span>
        </h2>
        <p className="content_navi">
          <span className="home">HOME</span>
          <span className="gt">&gt;</span>
          <span>회사소개</span>
          <span className="gt">&gt;</span>
          <span>오시는 길</span>
        </p>
      </div>
      <div className="content_in">
        <div className="sub_title"></div>
        <center>
          <br />
          <br />
          <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={17}>
            
            <Marker
              position={center}
              onClick={() => {
                setSelected(center);
              }}
              title={markerTitle}
            />
            {selected ? (
              <InfoWindow
                position={selected}
                onCloseClick={() => {
                  setSelected(null);
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: contentString }} />
              </InfoWindow>
            ) : null}
          </GoogleMap>
          <br />
          <br />
          <img src={img} alt="회사 위치" />
        </center>
      </div>
    </div>
  );
};

export default Page06;
