import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/common/logo.jpg";

const Header = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  useEffect(() => {
    const str = window.location.pathname.split("/");
    const temp = parseInt(str[2]?.charAt(str[2]?.length - 1), 10);
    setActiveIndex(temp - 1);
  }, []);

  return (
    <div id="header">
      <div className="header_in">
        <h1>
          <Link to="/">
            <img src={logo} alt="DYNA SYSTEM" />
          </Link>
        </h1>
        <div className="topmenu_section">
          <ul id="topmenu">
            {menuItems.map((menu, index) => (
              <li
                key={index}
                className={activeIndex === index ? "on" : ""}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <Link to={menu.link} className={`_menu0${index + 1}`}>
                  {menu.title}
                </Link>
                <div
                  className={`_menu0${index + 1} Inbsub`}
                  style={{ display: activeIndex === index ? "block" : "none" }}
                >
                  <ul className={`sub_menu menu0${index + 1}`}>
                    {menu.subMenu.map((subItem, subIndex) => (
                      <li key={subIndex}>
                        <Link to={subItem.link}>{subItem.title}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <ul className="top_lang_btn" style={{ float: "right", marginRight: 20 }}>
          <li className="active">
            <Link to="http://dynasystem.co.kr:8090/">INTRANET</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

const menuItems = [
  {
    title: "회사소개",
    link: "/menu01/page01",
    subMenu: [
      { title: "회사개요", link: "/menu01/page01" },
      { title: "CEO 인사말", link: "/menu01/page02" },
      { title: "기업이념", link: "/menu01/page03" },
      { title: "회사연혁", link: "/menu01/page04" },
      { title: "회사조직", link: "/menu01/page05" },
      { title: "오시는 길", link: "/menu01/page06" },
    ],
  },
  {
    title: "사업소개",
    link: "/menu02/page01",
    subMenu: [
      { title: "사업분야", link: "/menu02/page01" },
      { title: "사업영역", link: "/menu02/page02" },
      { title: "솔루션", link: "/menu02/page03" },
      { title: "고객사/협력사", link: "/menu02/page04" },
    ],
  },
  {
    title: "홍보센터",
    link: "/menu03/page01",
    subMenu: [
      { title: "공지사항", link: "/menu03/page01" },
      { title: "포토갤러리", link: "/menu03/page02" },
      { title: "다운로드센터", link: "/menu03/page03" },
    ],
  },
  {
    title: "채용정보",
    link: "/menu04/page01",
    subMenu: [
      { title: "인재상", link: "/menu04/page01" },
      { title: "채용안내", link: "/menu04/page02" },
      { title: "복리후생", link: "/menu04/page03" },
    ],
  },
  {
    title: "고객지원",
    link: "mailto:kh.yim@dynasystem.co.kr",
    subMenu: [{ title: "고객문의", link: "mailto:kh.yim@dynasystem.co.kr" }],
  },
];

export default Header;
