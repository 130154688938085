import DefaultLayout from "view/common/defaultLayout";
import Page01 from "view/menu03/page01";
import Page01_detail from "view/menu03/page01_detail";
import Page02 from "view/menu03/page02";
import Page02_detail from "view/menu03/page02_detail";
import Page03 from "view/menu03/page03";
import Page03_detail from "view/menu03/page03_detail";

export const menu03Routes = [
  { path: "/menu03/page01", element: <DefaultLayout propsComponent={<Page01 />} /> },
  { path: "/menu03/page01/detail", element: <DefaultLayout propsComponent={<Page01_detail />} /> },
  { path: "/menu03/page02", element: <DefaultLayout propsComponent={<Page02 />} /> },
  { path: "/menu03/page02/detail", element: <DefaultLayout propsComponent={<Page02_detail />} /> },
  { path: "/menu03/page03", element: <DefaultLayout propsComponent={<Page03 />} /> },
  { path: "/menu03/page03/detail", element: <DefaultLayout propsComponent={<Page03_detail />} /> },
];
