import { useMutation } from "react-query";
import request from "utils/request";

const baseURL = "/companyInfo";

// 회사연혁 조회
export const useDisHistoryList = (options) => {
  return useMutation([baseURL, "disHistoryList"], (params) => request.callCmd(baseURL + "/DIS-HISTORY-LIST", params), { ...options });
};

// 회사개요조회
export const useDisHomePageMgtList = (options) => {
  return useMutation([baseURL, "disHomePageMgtList"], (params) => request.callCmd(baseURL + "/DIS-HOMEPAGEMGT-LIST", params), { ...options });
};
