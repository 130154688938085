import DefaultLayout from "view/common/defaultLayout";
import Page01 from "view/menu04/page01";
import Page02 from "view/menu04/page02";
import Page03 from "view/menu04/page03";

export const menu04Routes = [
  { path: "/menu04/page01", element: <DefaultLayout propsComponent={<Page01 />} /> },
  { path: "/menu04/page02", element: <DefaultLayout propsComponent={<Page02 />} /> },
  { path: "/menu04/page03", element: <DefaultLayout propsComponent={<Page03 />} /> },
];
