import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const LeftMenu = () => {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const str = location.pathname.split("/");
    const top = parseInt(str[1].charAt(str[1].length - 1)) - 1; 
    const left = parseInt(str[2].charAt(str[2].length - 1)) - 1;

    setActiveMenu({ top, left });
  }, [location]);

  const menuItems = [
    {
      title: "회사소개",
      link: "/menu01/page01",
      subItems: [
        { title: "회사개요", link: "/menu01/page01" },
        { title: "CEO 인사말", link: "/menu01/page02" },
        { title: "기업이념", link: "/menu01/page03" },
        { title: "회사연혁", link: "/menu01/page04" },
        { title: "회사조직", link: "/menu01/page05" },
        { title: "오시는 길", link: "/menu01/page06" },
      ],
    },
    {
      title: "사업소개",
      link: "/menu02/page01",
      subItems: [
        { title: "사업분야", link: "/menu02/page01" },
        { title: "사업영역", link: "/menu02/page02" },
        { title: "솔루션", link: "/menu02/page03" },
        { title: "고객사/협력사", link: "/menu02/page04" },
      ],
    },
    {
      title: "홍보센터",
      link: "/menu03/page01",
      subItems: [
        { title: "공지사항", link: "/menu03/page01" },
        { title: "포토갤러리", link: "/menu03/page02" },
        { title: "다운로드센터", link: "/menu03/page03" },
      ],
    },
    {
      title: "채용정보",
      link: "/menu04/page01",
      subItems: [
        { title: "인재상", link: "/menu04/page01" },
        { title: "채용안내", link: "/menu04/page02" },
        { title: "복리후생", link: "/menu04/page03" },
      ],
    },
    {
      title: "고객지원",
      link: "mailto:kh.yim@dynasystem.co.kr",
      subItems: [{ title: "고객문의", link: "mailto:kh.yim@dynasystem.co.kr" }],
    },
  ];

  return (
    <div className="left_menu FloatLeft" id="left_menu">
      <ul className="left_menu_list">
        {menuItems.map((item, index) => (
          <li key={index} className={activeMenu.top === index ? "active" : ""}>
            <Link to={item.link}>{item.title}</Link>
            <ul>
              {item.subItems.map((subItem, subIndex) => (
                <li key={subIndex} className={activeMenu.top === index && activeMenu.left === subIndex ? "active" : ""}>
                  <Link to={subItem.link}>{subItem.title}</Link>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LeftMenu;
