import React from "react";

const Error500 = () => {
  return (
    <div id="wrap" style={{ textAlign: "center", margin: "40px 0" }}>
      <div style={{ fontSize: "150px" }}>
      
      </div>
      <span style={{ fontSize: "40px", fontWeight: "bold", color: "#555", letterSpacing: "-.5px" }}>
        <br />
        서버 연결이 원활하지 않습니다.
      </span>
      <div
        style={{
          textAlign: "center",
          fontSize: "20px",
          background: "#bbb",
          width: "130px",
          margin: "20px auto",
          borderRadius: "5px",
          letterSpacing: "-.5px",
          fontWeight: "bold",
          textShadow: "1px 1px 0px #fff",
          boxShadow: "0px 0px 9px #8e8e8e",
        }}
      >
        <a href="#" onClick={() => window.history.back()} style={{ padding: "15px", display: "inline-block" }}>
          되돌아가기
        </a>
      </div>
    </div>
  );
};

export default Error500;
