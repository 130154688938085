import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Header from "view/common/header";
import Footer from "view/common/footer";
import LeftMenu from "view/common/leftMenu";
import "css/base.css";
import "css/common.css";
import "css/main.css";
import "css/content.css";
import "css/media.css";
import "css/sub.css";
import "css/board.css";

const DefaultLayout = ({ propsComponent }) => {
  const location = useLocation();
  const dom = useRef();

  useEffect(() => {
    if (
      location.pathname === "/menu03/page01" ||
      location.pathname === "/menu03/page02" ||
      location.pathname === "/menu03/page03" ||
      location.pathname === "/menu04/page02"
    ) {
      dom.current.id = "middle1";
    } else {
      dom.current.id = "middle";
    }
  }, [propsComponent]);

  return (
    <div id="wrap">
      <Header />
      <div className="content">
        <div id="middle" ref={dom}>
          <div className="sub_visual">
            <div className="sub_visual_in">&nbsp;</div>
          </div>

          <div className="sub_middle">
            <div className="sub_middle_in">
              <LeftMenu />
              {propsComponent}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default DefaultLayout;
