import DefaultLayout from "view/common/defaultLayout";
import Page01 from "view/menu02/page01";
import Page02 from "view/menu02/page02";
import Page03 from "view/menu02/page03";
import Page04 from "view/menu02/page04";

export const menu02Routes = [
  { path: "/menu02/page01", element: <DefaultLayout propsComponent={<Page01 />} /> },
  { path: "/menu02/page02", element: <DefaultLayout propsComponent={<Page02 />} /> },
  { path: "/menu02/page03", element: <DefaultLayout propsComponent={<Page03 />} /> },
  { path: "/menu02/page04", element: <DefaultLayout propsComponent={<Page04 />} /> },
];
