import React, { useEffect, useState } from "react";
import { useDisHomePageMgtList } from "hooks/services/companyInfo";

const Page01 = () => {
  const [data, setData] = useState({});

  //=============================================================================================================================================================================
  // 리액트쿼리
  //=============================================================================================================================================================================
  // 공지사항 조회================================================================================================================================
  const { isLoading: isLoadingList, mutateAsync: disHomePageMgtList } = useDisHomePageMgtList({
    onSuccess: (data) => {
      if (data?.error?.code === "0000") {
        setData(data?.result[0]);
      } else {
      }
    },
  });

  useEffect(() => {
    disHomePageMgtList([null, {}]);
  }, []);

  return (
    <div className="content_section FloatRight">
      <div className="content_top">
        <h2>
          회사개요<span>The Best Leader of Information Technology</span>
        </h2>
        <p className="content_navi">
          <span className="home">HOME</span>
          <span className="gt">&gt;</span>
          <span>회사소개</span>
          <span className="gt">&gt;</span>
          <span>회사개요</span>
        </p>
      </div>
      <div className="content_in">
        <div className="page1_1_bg">
          <table className="table_style01 page1_1">
            <tbody>
              <tr>
                <th>설립연도</th>
                <td>2015년도</td>
              </tr>
              <tr>
                <th>대표이사</th>
                <td>{data.boss}</td>
              </tr>
              <tr>
                <th>직원수</th>
                <td>{data.employees}명</td>
              </tr>
              <tr>
                <th>매출액</th>
                <td>{data.profit}원</td>
              </tr>
              <tr>
                <th>주요사업</th>
                <td>{data.majorBusiness}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="page1_1 page_content">
          <div className="box">
            <p className="title">고객에게 감동을 전하는 ICT 전문기업</p>
            <p className="txt">
              다이나시스템은 창립 이후 수행한 다양한 프로젝트 경험과 고객에 대한 철저한 이해를 바탕으로 고객에게 가장
              적합한 IT서비스를 제시하고 있으며, 고객 만족을 뛰어넘어 감동을 전하고 그 가치를 함께 나누는 고객과 함께
              (With You)하는 기업이 되고자 노력하고 있습니다. 이를 위해 '창의, 열정, 도전, 신뢰'를 갖춘 인재를 채용하고
              양성하는 일에 최선을 다하고 있습니다.
            </p>
          </div>
          <div className="box">
            <p className="title">경쟁력을 갖춘 전문기업으로의 도약</p>
            <p className="txt">
              통합 IT 서비스, 솔루션, 콘텐츠 개발, 모바일 융합서비스 사업 등 핵심사업의 선택과 집중의 경영을 통해 '한발
              앞선 경영'으로 고객의 다양한 요구를 충족시키는 ICT 전문기업으로 도약하고 있습니다.
            </p>
          </div>
          <div className="box">
            <p className="title">창의적이고 역동적인 인재중심의 기업</p>
            <p className="txt">
              DynaSystem은 역동적이고 창의적인 기술자들을 중심으로 고객이 만족하는 안정적인 고효율 시스템을 구축하자라는
              의미입니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page01;
