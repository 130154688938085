import { useMutation } from "react-query";
import request from "utils/request";

const baseURL = "/boards";

// 공지사항 게시판 조회
export const useDisNoticeList = (options) => {
  return useMutation([baseURL, "disNoticeList"], (params) => request.callCmd(baseURL + "/DIS-NOTICE-LIST", params), { ...options });
};

// 공지사항 게시판 상세조회
export const useDisNoticeDetailList = (options) => {
  return useMutation([baseURL, "disNoticeDetailList"], (params) => request.callCmd(baseURL + "/DIS-NOTICEDETAIL-LIST", params), { ...options });
};


// 다운로드센터 게시판 조회
export const useDisDownLoadCenterList = (options) => {
  return useMutation([baseURL, "disDownLoadCenterList"], (params) => request.callCmd(baseURL + "/DIS-DOWNLOADCENTER-LIST", params), { ...options });
};

// 다운로드센터 게시판 상세조회
export const useDisDownLoadCenterDetail = (options) => {
  return useMutation([baseURL, "disDownLoadCenterDetailList"], (params) => request.callCmd(baseURL + "/DIS-DOWNLOADCENTERDETAIL-LIST", params), { ...options });
};

// 포토갤러리 게시판 조회
export const useDisPictureList = (options) => {
  return useMutation([baseURL, "disPictureList"], (params) => request.callCmd(baseURL + "/DIS-PICTURE-LIST", params), { ...options });
};

// 포토갤러리 게시판 상세조회
export const useDisPictureDetailList = (options) => {
  return useMutation([baseURL, "disPictureDetailList"], (params) => request.callCmd(baseURL + "/DIS-PICTUREDETAIL-LIST", params), { ...options });
};

// 파일 다운로드
export const useDownBoardFile = (options) => {
  return useMutation(
    [baseURL, "downBoardFile"],
    (params) =>
      request.callCmd(baseURL + "/DOWN-BOARD-FILE", params, {
        responseType: "blob",
      }),
    { ...options }
  );
};