import React, { useEffect, useState, Fragment } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDisPictureDetailList, useDownBoardFile } from "hooks/services/boards";
import { handleFileDownload } from "utils/commonUtil";
import { Button } from "antd";
import "css/boardDetail.css";

const Page02_detail = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState({});

  //=============================================================================================================================================================================
  // 리액트쿼리
  //=============================================================================================================================================================================
  // 공지사항 상세조회================================================================================================================================
  const { isLoading: isLoadingList, mutateAsync: disPictureDetailList } = useDisPictureDetailList({
    onSuccess: (data) => {
      if (data?.error?.code === "0000") {
        setData(data?.result[0]);
        console.log(data?.result[0]);
      } else {
      }
    },
  });

  // 파일다운로드================================================================================================================================
  const { isLoading: isLoadingList1, mutateAsync: downBoardFile } = useDownBoardFile({});

  useEffect(() => {
    disPictureDetailList([null, { pctId: searchParams.get("pctId") }]);
  }, []);

  return (
    <div className="content_section FloatRight">
      <div className="content_top">
        <h2>
          포토갤러리<span>The Best Leader of Information Technology</span>
        </h2>
        <p className="content_navi">
          <span className="home">HOME</span>
          <span className="gt">&gt;</span>
          <span>홍보센터</span>
          <span className="gt">&gt;</span>
          <span>
            <a href="/sub03/notice_write.do">포토갤러리</a>
          </span>
          <span className="gt">&gt;</span>
          <span>
            <a>상세보기</a>
          </span>
        </p>
      </div>

      <div className="content_in">
        <div className="board_top"></div>

        <table className="info-table">
          <tbody>
            <tr>
              <th>제목</th>
              <td colSpan="6">{data.pctTitle}</td>
            </tr>

            <tr>
              <th>내용</th>
              <td colSpan="6" style={{ height: "300px", verticalAlign: "top" }}>
                <div>
                  {data?.pctText &&
                    data?.pctText.split(`\n`).map((item, idx) => {
                      return (
                        <Fragment key={idx}>
                          {item}
                          {data.pctText.split(`\n`).length > idx + 1 && <br />}
                        </Fragment>
                      );
                    })}
                </div>
                <div>
                  {data?.files?.map((item, index) => {
                    console.log("이미지 요청 url 확인", process.env.REACT_APP_URL);
                    return (
                      <div key={index}>
                        <br />
                        <img
                          style={{ width: "auto", height: "200px" }}
                          src={process.env.REACT_APP_URL + "/boards/SHOW-BOARD-IMAGE?path=" + item.path}
                        />
                      </div>
                    );
                  })}
                </div>
              </td>
            </tr>

            <tr>
              <th>첨부파일</th>
              <td colSpan="6">
                {data?.files?.map((item, index) => {
                  return (
                    <div key={index}>
                      <a
                        onClick={async () => {
                          const res = await downBoardFile([null, { fileId: item.fileId }]);
                          if (res.size === 0 && res.type === "text/xml") {
                          } else {
                            console.log(item.viewFilename);
                            handleFileDownload(res, item.viewFilename);
                          }
                        }}
                      >
                        {item.viewFilename}
                      </a>
                    </div>
                  );
                })}
              </td>
            </tr>

            <tr>
              <th>등록자</th>
              <td colSpan="4">{data.regMemberNm}</td>
              <th>등록일</th>
              <td>{data.regDate}</td>
            </tr>

            <tr>
              <th>수정자</th>
              <td colSpan="4">{data.modMemberNm}</td>
              <th>수정일</th>
              <td>{data.modDate}</td>
            </tr>
          </tbody>
        </table>

        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }} className="board_button">
          <Button
            onClick={() => {
              navigate("/menu03/page02");
            }}
          >
            목록
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Page02_detail;
