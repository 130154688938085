import img1 from "images/content/4_2img01.jpg";

const Page02 = () => {
  return (
    <div className="content_section FloatRight">
      <div className="content_top">
        <h2>
          채용안내<span>The Best Leader of Information Technology</span>
        </h2>
        <p className="content_navi">
          <span className="home">HOME</span>
          <span className="gt">&gt;</span>
          <span>채용정보</span>
          <span className="gt">&gt;</span>
          <span>채용안내</span>
        </p>
      </div>
      <div className="content_in">
        <div>
          <img src={img1} alt="채용안내" />
        </div>
      </div>
    </div>
  );
};

export default Page02;
