import { React, useEffect, useState } from "react";
import { useDisHistoryList } from "hooks/services/companyInfo";
import Page04_timeLine from "./page04_timeLine";

const Page04 = () => {
  //=============================================================================================================================================================================
  // useState
  //=============================================================================================================================================================================
  const [dataList, setDataList] = useState([]);

  //=============================================================================================================================================================================
  // 리액트쿼리
  //=============================================================================================================================================================================
  // 회사연혁 조회================================================================================================================================
  const { isLoading: isLoadingList, mutateAsync: disHistoryList } = useDisHistoryList({
    onSuccess: (data) => {
      if (data?.error?.code === "0000") {
        setDataList(data.result);
      } else {
      }
    },
  });

  useEffect(() => {
    disHistoryList([null, {}]);
  }, []);

  return (
    <div className="content_section FloatRight">
      <div className="content_top">
        <h2>
          회사연혁<span>The Best Leader of Information Technology</span>
        </h2>
        <p className="content_navi">
          <span className="home">HOME</span>
          <span className="gt">&gt;</span>
          <span>회사소개</span>
          <span className="gt">&gt;</span>
          <span>회사연혁</span>
        </p>
      </div>
      <div className="content_in">
        {/* 콘텐츠 드가는 곳 */}
        <div>
          <Page04_timeLine data={dataList} />
        </div>
        {/* 콘텐츠 드가는 곳 */}
      </div>
    </div>
  );
};

export default Page04;
