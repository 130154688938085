import React from "react";
import img from "images/content/2_2img01.jpg";

const Page02 = () => {
  return (
    <div className="content_section FloatRight">
      <div className="content_top">
        <h2>
          사업영역<span>The Best Leader of Information Technology</span>
        </h2>
        <p className="content_navi">
          <span className="home">HOME</span>
          <span className="gt">&gt;</span>
          <span>사업소개</span>
          <span className="gt">&gt;</span>
          <span>사업영역</span>
        </p>
      </div>
      <div className="content_in">
        <div>
          <img src={img} style={{ marginTop: "-25px" }} />
        </div>
      </div>
    </div>
  );
};

export default Page02;
