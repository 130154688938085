import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import img1 from "images/content/2_1img01.jpg";
import img2 from "images/content/2_1img02.jpg";
import img3 from "images/content/2_1img03.jpg";
import img4 from "images/content/2_1img04.jpg";

const Page01 = () => {
  const [activeTab, setActiveTab] = useState("tab01");
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get("tab") ? `tab${params.get("tab")}` : "tab01";
    setActiveTab(tab);
  }, [location]);

  const changeTab = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className="content_section FloatRight">
      <div className="content_top">
        <h2>
          사업분야<span>The Best Leader of Information Technology</span>
        </h2>
        <p className="content_navi">
          <span className="home">HOME</span>
          <span className="gt">&gt;</span>
          <span>사업소개</span>
          <span className="gt">&gt;</span>
          <span>사업분야</span>
        </p>
      </div>
      <div className="content_in">
        <div>
          <ul className="tabmenu01">
            <li className={activeTab === "tab01" ? "active" : ""} onClick={() => changeTab("tab01")}>
              <a href="#">컨설팅</a>
            </li>
            <li className={activeTab === "tab02" ? "active" : ""} onClick={() => changeTab("tab02")}>
              <a href="#">시스템통합</a>
            </li>
            <li className={activeTab === "tab03" ? "active" : ""} onClick={() => changeTab("tab03")}>
              <a href="#">아웃소싱</a>
            </li>
            <li className={activeTab === "tab04" ? "active" : ""} onClick={() => changeTab("tab04")}>
              <a href="#">솔루션</a>
            </li>
          </ul>
          <img id="tab01_content" src={img1} style={{ display: activeTab === "tab01" ? "block" : "none" }} />
          <img id="tab02_content" src={img2} style={{ display: activeTab === "tab02" ? "block" : "none" }} />
          <img id="tab03_content" src={img3} style={{ display: activeTab === "tab03" ? "block" : "none" }} />
          <img id="tab04_content" src={img4} style={{ display: activeTab === "tab04" ? "block" : "none" }} />
        </div>
      </div>
    </div>
  );
};

export default Page01;
