import React, { useState, useEffect } from "react";
import { Pagination } from "antd";
import { useDisNoticeList } from "hooks/services/boards";
import gif from "images/content/btn_search.gif";
import { useNavigate } from "react-router-dom";

const Page01 = () => {
  const navigate = useNavigate();

  const [dataList, setDataList] = useState([]);
  const [pageTotalCnt, setPageTotalCnt] = useState(0);
  const [pageInfo, setPageInfo] = useState({
    pageYn: "Y",
    pageIndex: 1,
    recordCountPerPage: 10,
  });
  const [param, setparam] = useState({
    searchOption: "ntcTitle",
    searchText: "",
    order: "DESC",
  });
  //=============================================================================================================================================================================
  // 리액트쿼리
  //=============================================================================================================================================================================
  // 공지사항 조회================================================================================================================================
  const { isLoading: isLoadingList, mutateAsync: disNoticeList } = useDisNoticeList({
    onSuccess: (data) => {
      if (data?.error?.code === "0000") {
        setDataList(data.result);
        setPageTotalCnt(data.addition?.totalCnt || 0);
      } else {
      }
    },
  });

  useEffect(() => {
    disNoticeList([pageInfo, param]);
  }, [pageInfo]);

  return (
    <div className="content_section FloatRight">
      <div className="content_top">
        <h2>
          공지사항<span>The Best Leader of Information Technology</span>
        </h2>
        <p className="content_navi">
          <span className="home">HOME</span>
          <span className="gt">&gt;</span>
          <span>홍보센터</span>
          <span className="gt">&gt;</span>
          <span>공지사항</span>
        </p>
      </div>

      <div className="content_in">
        <div className="board_top">
          <select
            id="search_key"
            value={param.searchOption}
            onChange={(e) => {
              setparam({ ...param, searchOption: e.target.value });
            }}
          >
            <option value="ntcTitle">제목</option>
            <option value="ntcText">내용</option>
            <option value="usrName">작성자</option>
          </select>
          <input
            id="search_val"
            type="text"
            className="board_search_txt"
            value={param.searchText}
            onChange={(e) => {
              setparam({ ...param, searchText: e.target.value });
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setPageInfo({ ...pageInfo, pageIndex: 1 });
              }
            }}
          />
          <img
            src={gif}
            alt="검색"
            onClick={() => {
              console.log(param);
              setPageInfo({ ...pageInfo, pageIndex: 1 });
            }}
          />
        </div>

        <table cellPadding="0" cellSpacing="0" border="0" className="board_table">
          <colgroup>
            <col width="12%" />
            <col width="*" />
            <col width="15%" />
            <col width="18%" />
            <col width="8%" />
          </colgroup>

          <thead>
            <tr>
              <th>번호</th>
              <th>제목</th>
              <th>작성자</th>
              <th>등록일</th>
              <th>조회</th>
            </tr>
          </thead>

          <tbody>
            {dataList.length > 0 ? (
              dataList.map((list) => (
                <tr key={list.ntcId}>
                  <td>{list.seq}</td>
                  <td className="AlignLeft">
                    <a
                      onClick={() => {
                        navigate("/menu03/page01/detail?ntcId=" + list.ntcId);
                      }}
                    >
                      {list.ntcTitle}
                    </a>
                  </td>
                  <td>{list.usrName}</td>
                  <td>{list.regDate}</td>
                  <td>{list.ntcHits}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">등록된 게시글이 없습니다</td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="page_navi">
          <Pagination
            align="center"
            current={pageInfo.pageIndex}
            total={pageTotalCnt}
            onChange={(index) => {
              setPageInfo({ ...pageInfo, pageIndex: index });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Page01;
