import { modal, notification } from 'utils/escapeAntd';

// - 다국어 사용안함
// 다국어 hook 없이 사용  
// export function I18N(value) {
//   return <Translation>{(t) => <span>{t(value)}</span>}</Translation>;
// }

// content: 메시지, config: 옵션변경 시 사용
export const AlertMsg = (content, config) => {
  modal.info({
    title: '', //<Button className="ant-modal-close" />,
    closable: true,
    okText: '확인',
    icon: null,
    width: 360,
    content: content || '',
    ...config
  });
};

// content: 메시지, config: 옵션변경 시 사용
export const ConfirmMsg = (content, config) => {
  modal.confirm({
    title: '',
    closable: true,
    okText: '확인',
    cancelText: '취소',
    icon: null,
    width: 360,
    content: content || '',
    ...config
  });
};

// type, 타입, message: 메시지, config: 옵션변경 시 사용
export const ToastMsg = (type, message, config) => {
  const option = {
    placement: 'bottomLeft', 
    message: message || '',
    duration: 2,
    ...config
  }
  if(type === 'success') {
    notification.success(option);
  } else if(type === 'error') {
    notification.error(option);
  } else if(type === 'info') {
    notification.info(option);
  } else if(type === 'warning') {
    notification.warning(option);
  }
};





export const handleFileDownload = (data, fileName) => {
  if(!data) return;
  const blob = new Blob([data]);
  const fileUrl = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = fileUrl;
  link.style.display = 'none';
  link.download = fileName || '';
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(fileUrl);
};

// 숫저에 콤마찍기
export const formatNumber = (value) => new Intl.NumberFormat().format(value);