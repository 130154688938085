import React, { useEffect, useState, Fragment } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDisNoticeDetailList, useDownBoardFile } from "hooks/services/boards";
import { handleFileDownload } from "utils/commonUtil";
import { Button } from "antd";
import "css/boardDetail.css";

const Page01_detail = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState({});

  //=============================================================================================================================================================================
  // 리액트쿼리
  //=============================================================================================================================================================================
  // 공지사항 조회================================================================================================================================
  const { isLoading: isLoadingList, mutateAsync: disNoticeDetailList } = useDisNoticeDetailList({
    onSuccess: (data) => {
      if (data?.error?.code === "0000") {
        setData(data?.result[0]);
        console.log(data?.result[0]);
      } else {
      }
    },
  });

  // 파일다운로드================================================================================================================================
  const { isLoading: isLoadingList1, mutateAsync: downBoardFile } = useDownBoardFile({
    onSuccess: (res) => {
      if (res.size === 0 && res.type === "text/xml") {
      } else {
        console.log(data.viewFilename);
        handleFileDownload(res, data.viewFilename);
      }
    },
  });

  useEffect(() => {
    disNoticeDetailList([null, { ntcId: searchParams.get("ntcId") }]);
  }, []);

  return (
    <div className="content_section FloatRight">
      <div className="content_top">
        <h2>
          공지사항<span>The Best Leader of Information Technology</span>
        </h2>
        <p className="content_navi">
          <span className="home">HOME</span>
          <span className="gt">&gt;</span>
          <span>홍보센터</span>
          <span className="gt">&gt;</span>
          <span>
            <a href="/sub03/notice_write.do">공지사항</a>
          </span>
          <span className="gt">&gt;</span>
          <span>
            <a>상세보기</a>
          </span>
        </p>
      </div>

      <div className="content_in">
        <div className="board_top"></div>

        <table className="info-table">
          <tbody>
            <tr>
              <th>제목</th>
              <td colSpan="4">{data.ntcTitle}</td>
              <th>조회수</th>
              <td>{data.ntcHits}</td>
            </tr>

            <tr>
              <th>내용</th>
              <td colSpan="6" style={{ height: "300px", verticalAlign: "top" }}>
                {data?.ntcText &&
                  data?.ntcText.split(`\n`).map((item, idx) => {
                    return (
                      <Fragment key={idx}>
                        {item}
                        {data.ntcText.split(`\n`).length > idx + 1 && <br />}
                      </Fragment>
                    );
                  })}
              </td>
            </tr>

            <tr>
              <th>첨부파일</th>
              <td colSpan="6">
                <a
                  onClick={() => {
                    downBoardFile([null, { fileId: data.fileId }]);
                  }}
                >
                  {data.viewFilename}
                </a>
              </td>
            </tr>

            <tr>
              <th>등록자</th>
              <td colSpan="4">{data.usrName}</td>
              <th>등록일</th>
              <td>{data.regDate}</td>
            </tr>

            <tr>
              <th>수정자</th>
              <td colSpan="4">{data.modMemberName}</td>
              <th>수정일</th>
              <td>{data.modDate}</td>
            </tr>
          </tbody>
        </table>

        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }} className="board_button">
          <Button
            onClick={() => {
              navigate("/menu03/page01");
            }}
          >
            목록
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Page01_detail;
