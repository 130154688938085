import React from 'react';
import { ConfigProvider, App as AntdApp } from 'antd';
//import EscapeAntd from 'utils/escapeAntd';
import EscapeAntd from '../utils/escapeAntd';
//import locale from "antd/lib/locale/ko_KR"; // Ant Design에 대한 한
import locale from "antd/lib/locale/ko_KR"; // Ant Design에 대한 한

const AntdConfig = ({ children }) => (
  <ConfigProvider 
    locale={locale}
    theme={{
      token: {
        colorPrimary: '#4380f8', // '#13c2c2',
        colorText: '#1f2023',
        colorTextDescription: '#838C9F',
        colorTextDisabled: '#B7BECD',
        colorTextHeading: '#1F2023',
        borderRadius: 4,
        headerBorderRadius: 0,
        controlInteractiveSize: 14,
        fontFamily:
          "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans KR', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
      },
    }}
  >
    <AntdApp>
      <EscapeAntd />
      {children}
    </AntdApp>
  </ConfigProvider>
)

export default AntdConfig;