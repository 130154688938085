import React from "react";
import img1 from "images/content/2_4img01.jpg";

const Page04 = () => {
  return (
    <div className="content_section FloatRight">
      <div className="content_top">
        <h2>
          고객사/협력사<span>The Best Leader of Information Technology</span>
        </h2>
        <p className="content_navi">
          <span className="home">HOME</span>
          <span className="gt">&gt;</span>
          <span>사업소개</span>
          <span className="gt">&gt;</span>
          <span>고객사/협력사</span>
        </p>
      </div>
      <div className="content_in">
        {/* 콘텐츠 드가는 곳 */}
        <div>
          <img src={img1} alt="고객사/협력사" />
        </div>
        {/* 콘텐츠 드가는 곳 */}
      </div>
    </div>
  );
};

export default Page04;
