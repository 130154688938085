import React from "react";
import img1 from "images/content/1_3img01.jpg";
import img2 from "images/content/1_3img02.jpg";

const Page03 = () => {
  return (
    <div className="content_section FloatRight">
      <div className="content_top">
        <h2>
          기업이념<span>The Best Leader of Information Technology</span>
        </h2>
        <p className="content_navi">
          <span className="home">HOME</span>
          <span className="gt">&gt;</span>
          <span>회사소개</span>
          <span className="gt">&gt;</span>
          <span>회사연혁</span>
        </p>
      </div>
      <div className="content_in">
        <div>
          <img src={img1} alt="회사연혁 이미지 1" />
          <img src={img2} alt="회사연혁 이미지 2" />
        </div>
      </div>
    </div>
  );
};

export default Page03;
