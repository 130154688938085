import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AntdConfig from "config/antdConfig";
import QueryConfig from "config/queryConfig";
import MainLayout from "view/common/mainLayout";
import { menu01Routes } from "routers/menu01";
import { menu02Routes } from "routers/menu02";
import { menu03Routes } from "routers/menu03";
import { menu04Routes } from "routers/menu04";
import { errorRoutes } from "routers/error";
import Error404 from "view/error/error404";

const App = () => {
  return (
    <AntdConfig>
      <QueryConfig>
        <BrowserRouter>
          <Routes>
            <Route key={"/"} path="/" element={<MainLayout />} />
            <Route key={"error404"} path="*" element={<Error404 />} />
            {menu01Routes.map((route) => (
              <Route key={route.path} path={route.path} element={route.element} />
            ))}
            {menu02Routes.map((route) => (
              <Route key={route.path} path={route.path} element={route.element} />
            ))}
            {menu03Routes.map((route) => (
              <Route key={route.path} path={route.path} element={route.element} />
            ))}
            {menu04Routes.map((route) => (
              <Route key={route.path} path={route.path} element={route.element} />
            ))}
            {errorRoutes.map((route) => (
              <Route key={route.path} path={route.path} element={route.element} />
            ))}
          </Routes>
        </BrowserRouter>
      </QueryConfig>
    </AntdConfig>
  );
};

export default App;
