import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_URL,
  timeout: 1000 * 10,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true,
});

instance.interceptors.request.use(
  (config) => {
    const { url } = config;
    
  // if( window.location.pathname === "/"){
  //   config.headers = {
  //     ...config.headers,
  //   };
  // }
    return config;
  },
);

instance.interceptors.response.use(
  (response) => {
    const { data } = response;

    if (process.env.REACT_APP_LOG === "1") {
      console.log(data?.addition?.cmdName || '', data);
    }

    return data || response;
  },
  (error) => {
    console.log("error:", error.response);
  
    if(error.response) {
      const { status } = error.response;
      if (status === 403) {
        window.location.href ="/errorOthers";
      } else if (status === 404) {
        window.location.href ="/error404";
      } else if (status === 500) {
      } else {
        if (status === 401) {
          if(window.location.pathname !== '/') {
            window.location.href = "/";
          }
        } else {
           window.location.href = "/error500";
        }
      }
    } else {
       window.location.href = "/error500";
    }
    return error;
  }
);

export const getCookie = (name) => {
	var cookieValue = null;
	if (document?.cookie) {
		var cookies = document.cookie.split(';');
		for (var i = 0; i < cookies.length; i++) {
			var cookie = cookies[i].replace(/^\s+|\s+$/g, "");
			if (cookie.substring(0, name.length + 1) === (name + '=')) {
				cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
				break;
			}
		}
	}
	return cookieValue;
}

/* Addition 객체 정의 */
export const Addition = {
	/** 커맨드 명 */
	cmdName: "",
	/** 현재페이지 */
	pageIndex: 1,
	/** 페이지갯수 */
	pageUnit: 10,
	/** 페이지사이즈 */
	pageSize: 10,
	/** 검색된 결과물 총 개수 */
	totalCnt: 0,
	/** 페이지 처리 여부 */
	pageYn: "N",
	/** firstIndex */
	firstIndex: 1,
	/** lastIndex */
	lastIndex: 1,
	/** recordCountPerPage */
	recordCountPerPage: 10
}

/* Param 객체 정의 */
export const Param = {
	paraName: "",
	paraValue: "",
}

/* ReqInputMsg 객체 정의 */
export const ReqInputMsg = (url, data) => {
  const urlArry = url.split('/');
  const addition = data?.length > 0 ? data[0] : {};
  const paramObj = data?.length > 1 ? data[1] : {};
  const paramList = Object.keys(paramObj).map((key) => {
    return {
      paraName: key,
      paraValue: paramObj[key]
    }
  });
  const reqInputMsg = {
		addition : {
			...Addition,
      cmdName: urlArry?.length > 0 ? urlArry[urlArry?.length-1] : '',
			...addition
		},
		paramList
	}
  
  if(data?.length > 2) {
    const formData = new FormData();
    formData.append("file", data[2]);
    formData.append("reqInputMsg", JSON.stringify(reqInputMsg));
    return formData;
  }
  return JSON.stringify(reqInputMsg);
}

const get = (url, config) => {
  return instance.get(url, config);
}

const put = (url, data, config) => {
  return instance.put(url, data, config);
}

const patch = (url, data, config) => {
  return instance.patch(url, data, config);
}

const post = (url, data, config) => {
  return instance.post(url, JSON.stringify(data), config);
}

const callCmd = (url, data, config) => {
  return instance.post(url, ReqInputMsg(url, data), config);
}

const request = {
  get,
  put,
  patch,
  post,

  callCmd,
};

export default request;