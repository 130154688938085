import React from "react";
import img from "images/content/1_2img03.jpg";
import img2 from "images/content/1_2img02.jpg";

const Page02 = () => {
  return (
    <div className="content_section FloatRight">
      <div className="content_top">
        <h2>
          CEO 인사말<span>The Best Leader of Information Technology</span>
        </h2>
        <p className="content_navi">
          <span className="home">HOME</span>
          <span className="gt">&gt;</span>
          <span>회사소개</span>
          <span className="gt">&gt;</span>
          <span>CEO 인사말</span>
        </p>
      </div>
      <div className="content_in">
        <div className="page1_2_top">
          <div className="page1_2_top_in">
            <p className="title01">고객 여러분 안녕하십니까</p>
            <p className="title02">
              방문해주신 여러분께 진심으로 감사드립니다. 여러분과의 만남을 더없이 기쁘고 소중하게 생각합니다.
            </p>
            <p className="title_img">
              <img src={img2} alt="CEO 인사말 이미지" />
            </p>
          </div>
          <p className="txt">
            21세기 대한민국 IT 산업의 미래를 개척해 나가는 다이나시스템은 항상 여러분의 곁에 있겠습니다.
          </p>
          <p className="txt">
            저희들은 창업이래 끊임없이 발전하는 미래 IT 기술에 폭넓게 대응하며 변화와 혁신을 통해 업계의 기술분야를
            리드하는 IT SOLUTION PROVIDER로써의 위상을 다져왔습니다.
          </p>
        </div>
        <div className="page1_2_bottom">
          <p className="txt">
            저희 임직원 일동은 그간 축적한 기반 기술을 바탕으로 현재 뿐만 아니라 미래 IT 기술에 있어서 가장 큰 화두이자,
            미래를 변화할 수 있는 열쇠로써 빅데이터, IOT, 클라우드 서비스 등을 통합적으로 구현할 수 있는 전문기업으로써
            다양한 솔루션과 서비스로 세상을 보다 즐겁고 보다 이롭게 하고자 합니다.
          </p>
          <p className="txt">
            고객중심의 가치관을 다이나시스템 임직원 모두가 공유하여, 고객의 생각을 현실로 변화시키는 과정을 통해
            고객으로부터 저희가 속한 사회의 모든 구성원에 이르기까지 삶의 질이 풍요로워 질 수 있도록 최선을 다할 것을
            약속드립니다.
          </p>
          <p className="txt">다이나시스템은 항상 여러분의 곁에서 여러분의 생각을 현실로 만들어드리겠습니다.</p>
        </div>
        <p className="page1_2_sign AlignRight">
          <img src={img} alt="CEO 서명 이미지" />
        </p>
      </div>
    </div>
  );
};

export default Page02;
