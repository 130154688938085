
import { QueryClient, QueryClientProvider } from 'react-query';
// import { AlertMsg, ToastMsg } from 'utils/commonUtil';

const QueryConfig = ({children}) => {
  const queryClient = new QueryClient({ 
    defaultOptions: {
      queries: {
        withCredentials: true,
        enabled: false,             // 바로 시작안함
        // onError: queryErrorHandler, // 전역 에러

        // refetchType: 'active',     // 현재 랜더링 되어있는 것만 refetching
        retry: 0,                     // 요청실패시 재시도 횟수 (기본:5) // 창이 포커싱 될 때
        refetchOnWindowFocus: false,  // 구성 요소가 마운트될 때
        // refetchOnMount: false,     // 네트워크 재연결이 발생할
        // refetchOnReconnect: false, // 1분 단위로 자동 refetch
        // refetchInterval: 60000,    // 1분 공통코드 등 무한주기 Infinity
        // staleTime: 6000,           // 5분 (cacheTime의 기본값은 5분이고 cacheTime| statleTime
      },
      mutations: {
        // onError: queryErrorHandler, // 전역 에러
        // onSuccess: querySuccessHandler,
      },    
    },
  }); // queryClient 생성

  // function queryErrorHandler (error) {
  //   const title = error?.message || "error connecting to server";
  // if (error?.code ==='ERR_BAD_REQUEST') {
  //     ToastMsg('잘못 된 요청. API 확인하세요.', {
  //       placement: 'top',
  //       description: `ERR_BAD_REQUEST: ${title}`,
  //     }); 
  //   }else {
  //     AlertMsg(title);
  //   }
  // }

  // function querySuccessHandler () {
  //   ToastMsg('정상 처리 되었습니다.'); 
  // }

  return (
    <QueryClientProvider client={queryClient}>
      {children}
    </QueryClientProvider>
  )
}

export default QueryConfig;