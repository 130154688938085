import React, { useEffect, useState } from "react";
import Header from "view/common/header";
import Footer from "view/common/footer";
import Main from "view/main/main";
import "css/base.css";
import "css/common.css";
import "css/main.css";
import "css/media.css";

const MainLayout = () => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    const length = document.querySelectorAll(".main_rolling > ul > li").length;
    const interval = setInterval(() => {
      rolling(length);
    }, 3500);

    return () => clearInterval(interval);
  }, [page]);

  const rolling = (length) => {
    const items = document.querySelectorAll(".main_rolling > ul > li");
    items.forEach((item) => item.classList.remove("active"));
    items[page].classList.add("active");

    setPage((prevPage) => (prevPage + 1) % length);
  };

  return (
    <div id="wrap">
      <Header />
      <Main />
      <Footer />
    </div>
  );
};

export default MainLayout;
