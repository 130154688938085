import DefaultLayout from "view/common/defaultLayout";
import Page01 from "view/menu01/page01";
import Page02 from "view/menu01/page02";
import Page03 from "view/menu01/page03";
import Page04 from "view/menu01/page04";
import Page05 from "view/menu01/page05";
import Page06 from "view/menu01/page06";

export const menu01Routes = [
  { path: "/menu01/page01", element: <DefaultLayout propsComponent={<Page01 />} /> },
  { path: "/menu01/page02", element: <DefaultLayout propsComponent={<Page02 />} /> },
  { path: "/menu01/page03", element: <DefaultLayout propsComponent={<Page03 />} /> },
  { path: "/menu01/page04", element: <DefaultLayout propsComponent={<Page04 />} /> },
  { path: "/menu01/page05", element: <DefaultLayout propsComponent={<Page05 />} /> },
  { path: "/menu01/page06", element: <DefaultLayout propsComponent={<Page06 />} /> },
];
