import React from "react";
import { useNavigate } from "react-router-dom";

import notice from "images/main/notice.jpg";
import pic1 from "images/main/pic1.jpg";
import pic2 from "images/main/pic2.jpg";
import pic3 from "images/main/pic3.jpg";

const Main = () => {
  const navigate = useNavigate();
  return (
    <div className="middle">
      <div className="main_visual">
        <div className="main_visual_box">
          <div className="main_visual_in">
            <div className="main_rolling">
              <ul>
                <li className="active">
                  <a href="#none">1</a>
                  <div className="main_rolling_in">
                    <p className="main_rolling_title">DYNA SYSTEM</p>
                    <p className="main_rolling_txt">
                      항상 고객과 함께 With You !<br />
                      고객에게 감동을 전하는 ICT 전문기업
                    </p>
                  </div>
                </li>
                <li>
                  <a href="#none">2</a>
                  <div className="main_rolling_in">
                    <p className="main_rolling_title">DYNA SYSTEM</p>
                    <p className="main_rolling_txt">
                      고객 중심의 가치창조 !<br />
                      창의적이고 역동적인 인재중심의 기업
                    </p>
                  </div>
                </li>
                <li>
                  <a href="#none">3</a>
                  <div className="main_rolling_in">
                    <p className="main_rolling_title">DYNA SYSTEM</p>
                    <p className="main_rolling_txt">
                      한발 앞선 경영 !<br />
                      경쟁력을 갖춘 전문기업으로의 도약
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <ul className="main_visual_btn">
              <li className="main_visual_btn01">
                <a href="#none" onClick={() => navigate("/menu02/page01?tab=01")}>
                  컨설팅
                </a>
              </li>
              <li className="main_visual_btn02">
                <a href="#none" onClick={() => navigate("/menu02/page01?tab=02")}>
                  시스템 통합
                </a>
              </li>
              <li className="main_visual_btn03">
                <a href="#none" onClick={() => navigate("/menu02/page01?tab=03")}>
                  IT 아웃소싱
                </a>
              </li>
              <li className="main_visual_btn04">
                <a href="#none" onClick={() => navigate("/menu02/page03")}>
                  솔루션
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="notice">
        <p className="notice_title">NEWS &amp; NOTICE</p>
        <div className="notice_content">
          <div className="notice_content_in">
            <div className="FloatLeft notice_left">
              <p className="notice_left_title">다이나시스템 홈페이지가 새롭게 단장되었습니다.</p>
              <p className="notice_left_txt">
                2018년 봄을 맞이하여 알찬한 해를 보내고자
                <br />
                새 마음 새 뜻을 담아 홈페이지를 리뉴얼 하였습니다.
                <br />
                시간내어 둘러보시고 좋은 의견 많이 남겨주시기를 바랍니다.
              </p>
              <p className="notice_more more_btn">
                {/* <a href="/sub03/notice.do">자세히보기</a> */}
                <a href="#none" onClick={() => navigate("/menu01/page01")}>
                  자세히보기
                </a>
              </p>
            </div>
            <div className="FloatRight notice_right">
              {/* 첫번째이미지 */}
              <img src={notice} alt="notice images" />
            </div>
          </div>
        </div>
      </div>
      <div className="pic">
        <div className="pic_in">
          <div className="pic01">
            <div className="FloatLeft pic01_left">
              {/* <img src="/images/hp/main/pic1.jpg" alt="pic01 images" /> */}
              <img src={pic1} alt="pic01 images" />
            </div>
            <div className="FloatRight pic01_right">
              <p className="pic01_title">COSTOMER</p>
              <p className="pic01_txt">
                다이나 시스템과 함께 역사를 만들어간
                <br />
                주요 고객사 소개 페이지입니다. 공기업, 금융권 및<br />
                다양한 기업과의 레퍼런스를 확인하실 수 있습니다.
              </p>
              <p className="pic_more more_btn">
                <a href="#none" onClick={() => navigate("/menu02/page04")}>
                  자세히보기
                </a>
              </p>
            </div>
          </div>
          <div className="pic02">
            <div className="FloatLeft pic02_left">
              <p className="pic02_title">PR CENTER</p>
              <p className="pic02_txt">
                다이나 시스템의 홍보센터입니다.
                <br />
                각종 홍보와 관련된 뉴스 및 정보를
                <br />
                확인하실 수 있습니다.
              </p>
              <p className="pic_more more_btn">
                <a href="#none" onClick={() => navigate("/menu03/page01")}>
                  자세히보기
                </a>
              </p>
            </div>
            <div className="FloatRight pic02_right">
              {/* <img src="/images/hp/main/pic2.jpg" alt="pic02 images" /> */}
              <img src={pic2} alt="pic02 images" />
            </div>
          </div>
          <div className="pic03">
            <div className="FloatLeft pic03_left">
              {/* <img src="/images/hp/main/pic3.jpg" alt="pic03 images" /> */}
              <img src={pic3} alt="pic03 images" />
            </div>
            <div className="FloatRight pic03_right">
              <p className="pic03_title">APPLICATION</p>
              <p className="pic03_txt">
                다이나 시스템에서는 함께 능력을 향상시켜나갈
                <br />
                재능있고 발전하는 인재를 찾습니다.
                <br />
                입사지원 항목을 상세히 확인 후 지원해주세요.
              </p>
              <p className="pic_more more_btn">
                <a href="#none" onClick={() => navigate("/menu04/page02")}>
                  자세히보기
                </a>
              </p>
            </div>
          </div>
          <p className="pic_left_bg">&nbsp;</p>
        </div>
      </div>
    </div>
  );
};

export default Main;
